import React from 'react';
import { withPrefix } from 'gatsby';
import Layout from '../../components/layouts/project';

const IndexPage = () => (
  <Layout
    title="Phoenix Rising"
    description="Help the phoenix Arkayle in this puzzler written for the second iteration of the Game Prototype Challenge, a week-long game jam that took place between December 13, 2010 and December 20, 2010."
  >
    <div>
      <h1 className="my-12 mx-8 text-center">Phoenix Rising</h1>
      <div className="demo py-12 overflow-x-auto bg-gray-900">
        <iframe
          className="phoenix-rising border-0 overflow-hidden block"
          src={withPrefix('/PhoenixRising/index.html')}
        />
      </div>
      <div className="details m-auto py-12 px-8 max-w-2xl">
        <h2>Instructions</h2>
        <p>
          Collect all of the twigs to advance to the next level! Move and use
          your abilities wisely as the ice will melt in Arkayle&apos;s wake.
        </p>
        <p>
          <em>
            Note: you may need to click on the game before it will accept
            keyboard input.
          </em>
        </p>
        <table className="controls">
          <thead>
            <tr>
              <th>Key</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>W or Up</td>
              <td>Move up</td>
            </tr>
            <tr>
              <td>A or Left</td>
              <td>Move left</td>
            </tr>
            <tr>
              <td>S or Down</td>
              <td>Move down</td>
            </tr>
            <tr>
              <td>D or Right</td>
              <td>Move right</td>
            </tr>
            <tr>
              <td>1</td>
              <td>Toggle fireball</td>
            </tr>
            <tr>
              <td>2</td>
              <td>Toggle freeze</td>
            </tr>
            <tr>
              <td>Space or Enter</td>
              <td>Start game or advance to the next level</td>
            </tr>
          </tbody>
        </table>

        <h2>Details</h2>
        <p>
          <em>Phoenix Rising</em> is a HTML5/JavaScript-based puzzler written
          for the{' '}
          <a href="https://web.archive.org/web/20111006061906/http://gameprototypechallenge.com/v2">
            second iteration of the Game Prototype Challenge
          </a>
          , a week-long game jam that took place between December 13, 2010 and
          December 20, 2010. It was my first-ever attempt at writing a game and
          also my first real exposure to the canvas element introduced in the
          HTML5 specification.
        </p>
        <p>
          At the time, using canvas was quite the novel approach as existing
          game engines largely relied on DOM manipulation. This was likely due
          to the poor performance of canvas, especially when the dimensions are
          large. With a fairly beefy laptop (Core 2 Duo T7500 / GeForce 9500M
          GS), re-rendering a 1000&times;500px canvas every 50ms caused Firefox
          3.6 to struggle, although performance was not terrible in Firefox 4
          and Chrome 8 due to hardware acceleration. In hindsight, this game
          didn&apos;t really need a proper game loop as the canvas only needed
          to update on player input.
        </p>
        <p>
          There were a couple of other interesting things of note. I opted to
          store level information in XML files, using various symbols to
          represent each type of tile. This meant that I could easily add
          additional levels (with the bottleneck being creative enough to
          actually come up with new puzzles). I also found that canvas&apos;s{' '}
          <code>drawImage</code> method tries to render an image onto the canvas
          even if it hasn&apos;t been loaded yet. Creating a new{' '}
          <code>Image</code> object and setting the <code>onload</code> and{' '}
          <code>src</code> properties was one way to resolve that, although it
          led to a bunch of flickering in Firefox 3.6 due to the game loop.
          Preloading all images at the start ended up being a better approach.
        </p>
      </div>
    </div>
  </Layout>
);

export default IndexPage;
